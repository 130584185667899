.hero {
    padding-top: 514px;
    position: relative;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero--details {
    background-position: 50% 20%;
    padding-top: 368px;
}

.hero__title {
    font-size: 64px;
    color: var(--cream);
    font-weight: bold;
}

.hero__title--details {
  font-size: 40px;
}

.hero__content {
    z-index: 1;
    position: absolute;
    right: 40px;
    bottom: 0;
}

.houses__slider .slider {
    padding-top: 0;
    padding-bottom: 2px;
}

@media only screen and (max-width: 640px) {
    .hero__content {
        left: 0;
    }

    .hero__title {
        font-size: 48px;
    }
}

.loading__title {
    font-size: 64px;
    color: var(--cream);
    font-weight: bold;
}

.ring {
  margin: 0 auto;
  position: relative;
  width:300px;
  height:300px;
  background:transparent;
  border:3px solid var(--black);
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color: var(--cream);
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px var(--cream);
}

.ring:before {
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid var(--cream);
  border-right:3px solid var(--cream);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}

.ring__logo {
    position: absolute;
    top: 44%;
    left: 0;
    right: 0;
    max-width: 300px;
    margin: 0 auto;
}

.ring__logo svg {
    display: block;
    margin: 0 auto;
}

@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}