.button {
    color: var(--white);
    border-radius: 8px;
    background-color: var(--cream);
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button:hover {
    background-color: var(--dark-cream);
    -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button--about {
    padding: 11px 68px;
    text-align: center;
}