html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --cream: #C89856;
  --dark-cream: #9a6e32;
  --white: #fff;
  --black: #000;
}

.container {
  width: auto;
  max-width: 1280px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 78px;
  padding-right: 78px;
}

.container--loading {
  padding: 186px 0;
  text-align: center;
}

.link {
  color: var(--cream);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
}

.link:hover {
  color: var(--dark-cream);
  -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.vertical-line {
  width: 2px;
  height: 100%;
  min-height: 200px;
  background-color: #C89856;
  border: 0;
  margin: 0 48px;
  border-radius: 8px;
}

.horizontal-line {
  max-width: 600px;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background-color: #C89856;
  border: 0;
  border-radius: 8px;
}

.icon--facebook {
  display: inline-block;
  background-size: cover;
  margin-right: 10px;
  background-image: url("./images/facebook.png");
  width: 24px;
  height: 24px;
}

.icon--instagram {
  display: inline-block;
  background-size: cover;
  margin-right: 10px;
  background-image: url("./images/icon-instagram.svg");
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 940px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .vertical-line {
    width: 200px;
    min-height: 2px;
    margin: 20px 0;
  }
}