.about {
    padding-top: 64px;
    padding-bottom: 82px;
}

.about--page {
    padding-bottom: 0;
}

.about__content {
    max-width: 986px;
    margin: 0 auto;
    text-align: center;
}

.about__title {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--black);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.about__description {
    margin-bottom: 45px;
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
    text-align: center;
}

.about--page .about__description {
    margin-bottom: 0;
    margin-top:0 ;
    text-align: left;
}

@media only screen and (max-width: 640px) {
    .about__title {
        font-size: 30px;
    }
}