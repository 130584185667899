.principles {
    background-color: var(--cream);
    padding-top: 64px;
    padding-bottom: 82px;
}

.principles__title {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--white);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.principles__element {
    color: var(--white);
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.7;
}

.principles__columns {
    display: flex;
    font-size: 18px;
    line-height: 1.7;
    color: var(--white);
    font-weight: 600;
}

.principles__column {
    margin-right: 81px;
    width: 30%;
}

.principles__column:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 940px) {
    .principles__columns {
        flex-direction: column;
    }

    .principles__column {
        margin-right: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 940px) {
    .principles__title {
        font-size: 30px;
    }
}