.details {
    padding: 64px 0;
}

.details__title {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--black);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.details__subtitle {
    font-size: 25px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
}

.details__description {
    font-size: 14px;
}

.details__box {
    display: flex;
    justify-content: space-between;
}

.details__box:nth-child(2n) .details__left {
    order: 1;
}

.details__image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid var(--cream);
}

.details__left {
    display: flex;
    align-items: center;
    width: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid var(--cream);
}

.details__right {
    width: 100%;
}

.details__element {
    margin-top: 20px;
    color: var(--black);
    font-weight: bold;
}

.details__element:first-child {
    margin-top: 0;
}

.details__list {
    list-style: none;
}
  
.details__list .details__element::before {
    content: "\2022";
    color: var(--cream);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

@media only screen and (max-width: 940px) {
    .details__box {
        flex-direction: column;
        align-items: center;
    }
    
    .details__left {
        width: 100%;
        padding-top: 200px;
    }

    .details__right {
        width: 100%;
    }

    .details__list {
        padding-left: 20px;
    }

    .details__box:nth-child(2n) .details__left {
        order: unset;
    }
}