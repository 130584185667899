.about-house {
    padding-top: 40px;
    padding-bottom: 40px;
}

.about-house__content {
    max-width: 986px;
    margin: 0 auto;
    text-align: center;
}

.about-house__title {
    margin: 0;
    color: var(--cream);
    font-size: 64px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
}

.about-house__text {
    font-size: 18px;
    color: var(--cream);
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
}

.about-house__text:after {
    content: " ";
    display: block;
    max-width: 145px;
    width: 100%;
    height: 2px;
    margin: 40px auto;
    background-color: var(--cream);
}

.about-house__image-box {
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
}

.about-house__image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.about-house__description {
    margin: 0;
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
    text-align: center;
}

@media only screen and (max-width: 640px) {
    .about-house__title {
        font-size: 30px;
    }
    
    .about-house__description {
        font-size: 18px;
    }
}