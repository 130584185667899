.contact {
    padding-top: 64px;
    padding-bottom: 82px;
}

.contact__title {
    margin-top: 0;
    margin-bottom: 48px;
    color: var(--black);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.contact__info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact__left, .contact__right {
    flex: 1 1 0px;
    text-align: center;
}

.contact__link {
    font-weight: bold;
}

.link__social {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 252px;
    margin: 14px auto;
}

.contact__text {
    margin: 14px 0;
    display: block;
    font-size: 18px;
    line-height: 1.7;
    font-weight: bold;
}

@media only screen and (max-width: 940px) {
    .contact__info {
        flex-direction: column;
    }
}

@media only screen and (max-width: 640px) {
    .contact__title {
        font-size: 30px;
    }
}
