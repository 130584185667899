.header {
    width: 100%;
    background-color: var(--light-grey);
}

.nav {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}

.nav__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.nav__item {
    display: inline-block;
    padding: 0 14px;
}

.nav__item:last-child {
    padding-right: 0;
}

.nav__item .active {
    color: var(--cream);
}

.nav__link {
    display: flex;
    align-items: center;
    padding: 13px 0;
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.nav__link:hover {
    color: var(--cream);
    -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.nav__logo {
    z-index: 900;
    position: relative;
    display: flex;
    align-items: center;
}

.nav__wrapper {
    display: none;
}

.hamburger {
    position: relative;
    z-index: 900;
    padding: 14px 0;
    margin-top: 8px;
    cursor: pointer;
}

.hamburger__slice {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: .375rem;
    background-color: var(--cream);
    -webkit-transition: all .25s cubic-bezier(.25,.46,.45,.94);
    transition: all .25s cubic-bezier(.25,.46,.45,.94);
}

.is-overflow {
    overflow: hidden;
}

.is-open .hamburger__slice:first-child {
    opacity: 0;
    top: 5px;
    background-color: var(--cream);
    transform: rotate(270deg);
    transition: all .25s cubic-bezier(.25,.46,.45,.94);
}

.is-open .hamburger__slice:nth-child(2) {
    background-color: var(--cream);
    transform: rotate(45deg);
    transition: all .25s cubic-bezier(.25,.46,.45,.94);
}

.is-open .hamburger__slice:last-child {
    margin-top: -7px;
    background-color: var(--cream);
    transform: rotate(-45deg);
    transition: all .25s cubic-bezier(.25,.46,.45,.94);
}

@media only screen and (max-width: 940px) {
    .header .nav__list {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 20px 0;
        width: 100%;
        height: 100vh;
        background-color: var(--white);
        flex-direction: column;
        transform: translateY(-100%);
        -webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .is-active {
        top: 56px !important;
        transform: translateY(0) !important;
        transition: all .25s cubic-bezier(.25,.46,.45,.94);
    }

    header .nav__item {
        padding: 0;
        margin: 14px 0;
    }

    .header .nav__link {
        padding: 0;
    }


    .nav__wrapper {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .nav__list {
        flex-direction: column;
    }
}