.privacy {
    padding: 64px 0;
}

.privacy__title {
    margin: 0;
    color: var(--black);
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
}

.privacy__description {
    font-size: 14;
}