.slider {
    padding-top: 64px;
    padding-bottom: 64px;
}

.slider__title {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--black);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.slick-slider {
    position: relative;
    margin: 30px auto;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-initialized .slick-slide {
    display: block;
}
.slick__card {
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
}

.slick__card-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.slick-arrow {
    z-index: 200;
    color: transparent;
    outline: none;
    background: transparent;
    border: 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 40%;
    border: none;
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;
}

.slick-next:before, .slick-prev:before {
    content: " ";
    cursor: pointer;
    background-image: url('../images/arrow.svg');
    background-repeat: no-repeat;
    display: block;
    width: 64px;
    height: 64px;
}

.slick-prev::before {
    transform: scaleX(-1);
}

.slick__wrapper {
    margin: 15px 0;
}

.slick__text {
    margin: 0;
    text-align: center;
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
}

.grey {
    color: gray;
    font-weight: bold;
    text-transform: uppercase;
}

.highlight {
    color: var(--cream);
    font-weight: bold;
    text-transform: uppercase;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

.slick-dots li:last-child {
    margin-right: 0;
}

.slick-active button:before {
    background-color: var(--cream) !important;
    opacity: 1 !important;
}

.slick-dots li button:before {
    content: " ";
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 14px;
    height: 14px;
    text-align: center;
    opacity: .25;
    border-radius: 50%;
    background-color: var(--black);
}

.slick__card {
    padding-bottom: 0;
}

.slick-upper .slick__card {
    border-radius: 8px;
    border: 1px solid var(--cream);
}

.slick-thumbnails {
    max-width: 600px;
}

.slick-thumbnails .slick-slide {
    cursor: pointer;
    max-width: 150px;
    margin-right: 12px;
    width: 100%;
}

.slick__thumbnail {
    border-radius: 8px;
    border: 1px solid var(--cream);
}

.slick-thumbnails .slick-slide:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 1024px) {
    .slick-next:before, .slick-prev:before {
        width: 30px;
        height: 30px;
        background-size: contain;
    }
}

@media only screen and (max-width: 940px) {
    .slick-next:before, .slick-prev:before {
        display: none;
    }
}

@media only screen and (max-width: 640px) {
    .slider__title {
        font-size: 30px;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-dots {
        margin: 0;
    }

    
    .home__house-slider {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 517px) {
    .slick-thumbnails .slick-list {
        height: 100px;
    }
}