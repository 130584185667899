.footer {
    padding: 20px 0;
    border-top: 1px solid var(--cream);
}

.footer__container {
    text-align: center;
}

.footer .nav {
    justify-content: space-around;
}

.copyright {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
}

@media only screen and (max-width: 640px) {
    .footer .nav {
        flex-direction: column;
        justify-content: unset;
    }

    .footer .nav__logo {
        justify-content: center;
    }

    .footer .nav__list {
        justify-content: center;
    }

    .footer .nav__item {
        padding: 0 14px;
    }
}