.technical {
    padding-top: 40px;
    padding-bottom: 40px;
}

.technical__content {
    max-width: 986px;
    margin: 0 auto;
    text-align: center;
}

.technical__title {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--black);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
}

.technical__box {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    border: 1px solid var(--cream);
}

.technical__description {
    padding: 0 20px;
    font-weight: bold;
}

.technical__sub-title {
    padding: 0 20px;
    font-weight: bold;
    text-align: center;
}

.technical__image {
    width: 100%;
    height: 100%;
    display: block;
}

.technical__info {
    padding: 64px 0;
    background-color: var(--cream);
    margin-top: 30px;
}

.technical__info .technical__sub-title {
    color: var(--white);
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.technical__item {
    margin-top: 20px;
    color: var(--white);
    font-weight: bold;
}

.technical__item:first-child {
    margin-top: 0;
}

.technical__list {
    max-width: 896px;
    margin: 0 auto;
}

.table {
    border-collapse: collapse;
    margin: 0 auto;
}

.table__column {
    padding: 10px 0;
}

.table__column:first-child {
    text-align: left;
    padding-right: 30px;
}

sup {
    line-height: 0.6;
}

.table__row {
    border-top: 1px solid var(--cream);
}

.table__row:first-child {
    border-top: none;
}

.table__number {
    color: red;
}